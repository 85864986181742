import { buildAuthHeaders } from 'utils/buildAuthHeaders';
const api = process.env.REACT_APP_TOUR_API;
export const getToursByAid = async (aid) => {
  const headers = buildAuthHeaders();
  try {
    const res = await fetch(`${api}/api/v2/${aid}/tours`, {
      headers,
    });
    return await res.json();
  } catch (error) {
    throw error;
  }
};

export const getStop = async (aid, sid) => {
  const myHeaders = buildAuthHeaders();
  try {
    const res = await fetch(`${api}/api/v2/${aid}/stops/${sid}`, {
      headers: myHeaders,
      credentials: 'include',
    });
    return await res.json();
  } catch (error) {
    throw error;
  }
};
