import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import AuthLoader from './common/loaders/AuthLoader';
import Header from './Header/Header';
import Routes from './Routes';

const App = () => {
  const { keycloak, initialized } = useKeycloak();
  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [initialized, keycloak]);
  if (!initialized || !keycloak.authenticated) return <AuthLoader />;
  return (
    <>
      <Header />
      <Routes />
    </>
  );
};

export default App;
