import { buildAuthHeaders } from 'utils/buildAuthHeaders';

const atlasApi = process.env.REACT_APP_ATLAS_API;
const atlasKey = process.env.REACT_APP_ATLAS_KEY;

export const getMapInfo = async (mapId) => {
  const headers = buildAuthHeaders();
  try {
    const res = await fetch(`${atlasApi}/config?map=${mapId}&key=${atlasKey}`, {
      headers,
    });
    return await res.json();
  } catch (error) {
    throw error;
  }
};
