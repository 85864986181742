import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import clickOutsideHandler from '../../../utils/clickOutsideHandler';
import './Dropdown.scss';

const Dropdown = (props) => {
  const { children = [], close } = props;
  const dropDownRef = useRef();
  useEffect(() => {
    const dropDown = dropDownRef.current;
    const handleOutsideClick = (e) => {
      clickOutsideHandler({
        eventTarget: e.target,
        elementArray: [dropDown],
        toggleCallback: close,
      });
    };
    if (dropDown) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      dropDown && document.removeEventListener('click', handleOutsideClick);
    };
  }, [close]);

  const renderOptions = () => {
    if (typeof children === 'object') {
      const classes = classnames({
        'dropdown-option': true,
        last: true,
      });
      return (
        <li key="single" className={classes} onClick={close}>
          {children}
        </li>
      );
    }
    return children.map((option, i) => {
      if (!option) return null;
      const classes = classnames({
        'dropdown-option': true,
        last: i === children.length - 1,
      });

      return (
        <li key={i} className={classes} onClick={close}>
          {option}
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <ul className={`dropdown-list`} ref={dropDownRef}>
        {renderOptions()}
      </ul>
    </React.Fragment>
  );
};

export default Dropdown;
