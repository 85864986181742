import { getMapInfo } from 'api/mapsApi';
import Cards from 'components/Cards/Cards';
import { useEffect, useState } from 'react';
import { getAssetsUrl, getCloudinaryPortalUrl } from 'utils/assets';
import { buildCardButton, buildCard } from 'utils/cardUtils';
import {
  handleUrlOpen,
  getNFEMapUrl,
  getCMSUrl,
  interactiveMapProductPage,
} from 'utils/concept3dLinks';

const Map = (props) => {
  const [mapConfigs, setMapConfigs] = useState([]);
  const { maps = [], showProductPage } = props;

  useEffect(() => {
    const getMaps = async () => {
      const promises = [];
      maps.forEach((id) => {
        promises.push(getMapInfo(id));
      });
      const mapData = await Promise.all(promises);
      setMapConfigs(mapData);
    };
    getMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCards = () => {
    const cards = mapConfigs.map(({ mapName, map, sidebarImageUrl }) => {
      const title = `${mapName}`;
      const image = sidebarImageUrl
        ? getAssetsUrl(map, sidebarImageUrl)
        : getCloudinaryPortalUrl('nfe.jpg');
      const buttons = [
        buildCardButton({
          name: 'CMS',
          handleClick: () => handleUrlOpen(getCMSUrl(map)),
        }),
        buildCardButton({
          name: 'Interactive Map',
          handleClick: () => handleUrlOpen(getNFEMapUrl(map)),
        }),
      ];
      return buildCard({ image, title, buttons });
    });
    const signUpCard = () => {
      return buildCard({
        image: getCloudinaryPortalUrl('nfe.jpg'),
        title: 'Interactive Map',
        description: 'Check out our interactive map platform!',
        buttons: [
          buildCardButton({
            name: 'Product Page',
            handleClick: () => handleUrlOpen(interactiveMapProductPage),
          }),
          buildCardButton({
            name: 'Sample Map',
            handleClick: () => handleUrlOpen(getNFEMapUrl('1216')),
          }),
        ],
      });
    };
    return showProductPage ? [signUpCard()] : cards;
  };

  return <Cards title="Interactive Map & CMS" cards={createCards()} />;
};

export default Map;
