export const buildCard = ({
  title = '',
  image = '',
  description = '',
  buttons = [],
}) => {
  return {
    title,
    image,
    buttons,
    description,
  };
};

export const buildCardButton = ({
  name,
  handleClick,
  icon = 'arrow_forward',
}) => {
  return {
    name,
    handleClick,
    icon,
  };
};
