import Cards from 'components/Cards/Cards';
import { getCloudinaryPortalUrl } from 'utils/assets';
import { handleUrlOpen } from 'utils/concept3dLinks';

const Help = () => {
  const cards = [
    {
      title: 'Interactive Map',
      description: 'Need help with Interactive Map or CMS?',
      image: getCloudinaryPortalUrl('help.jpg'),
      buttons: [
        {
          name: 'Enter',
          handleClick: () =>
            handleUrlOpen(
              'https://help.concept3d.com/hc/en-us/categories/360001473034-Interactive-Map'
            ),
          icon: 'arrow_forward',
        },
      ],
    },
    {
      title: '360º Tour',
      description: 'Need help with 360º Tour or TourBuilder?',
      image: getCloudinaryPortalUrl('tour-help.jpg'),
      buttons: [
        {
          name: 'Enter',
          handleClick: () =>
            handleUrlOpen(
              'https://help.concept3d.com/hc/en-us/categories/115000870408-360-Tour-'
            ),
          icon: 'arrow_forward',
        },
      ],
    },
    {
      title: 'Submit a Ticket',
      description: 'Need to submit a bug?',
      image: getCloudinaryPortalUrl('help-ticket.jpg'),
      buttons: [
        {
          name: 'Enter',
          handleClick: () =>
            handleUrlOpen('https://help.concept3d.com/hc/en-us/requests/new'),
          icon: 'arrow_forward',
        },
      ],
    },
  ];
  return <Cards title="Concept3D Help" cards={cards} />;
};

export default Help;
