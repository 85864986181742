const cloudinaryPath = 'https://res.cloudinary.com/concept3d';

export const getAssetsUrl = (map, asset) => {
  return `${process.env.REACT_APP_ASSETS}/assets/${map}/${asset}`;
};

export const getCloudinaryPortalUrl = (asset) => {
  return `${cloudinaryPath}/image/upload/portal/${asset}`;
};

export const getTourBuilderAssetUrl = (type, asset) => {
  const folder = type.match(/videos/) ? 'video' : 'image';
  return `${cloudinaryPath}/${folder}/upload/q_auto,f_auto,h_200/${type}/${asset.replace(
    'mp4',
    'jpg'
  )}`;
};
