import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import CacheBuster from 'CacheBuster/CacheBuster';

import './styles/index.scss';

export const keycloak = new Keycloak();

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initConfig={{ onLoad: 'check-sso', checkLoginIframe: false }}
  >
    <BrowserRouter>
      <CacheBuster />
      <App />
    </BrowserRouter>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
