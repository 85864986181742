import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import './Avatar.scss';

const Avatar = ({ src, hideName = false }) => {
  const { keycloak } = useKeycloak();
  const token = keycloak.tokenParsed;
  const initials =
    token?.given_name && token?.family_name
      ? token?.given_name[0] + token?.family_name[0]
      : token?.email[0];
  return (
    <React.Fragment>
      {src ? (
        <img className="avatar" src={src} alt="user avatar" />
      ) : (
        <span className="avatar no-image">
          <span>{initials}</span>
        </span>
      )}
      {!hideName && <span>{token?.name}</span>}
    </React.Fragment>
  );
};

export default Avatar;
