const stageCheck = process.env.REACT_APP_STAGE || 'dev';
const stage = stageCheck !== 'prod' ? stageCheck : '';

export const getNFEMapUrl = (mapId) => {
  return `https://${stage ? stage : 'map'}.concept3d.com/?id=${mapId}`;
};

export const getTourBuilderUrl = () => {
  return `https://${stage}tour.concept3d.com/`;
};

export const getTourBuilderTourEditUrl = (aid, tourId) => {
  return `https://${stage}tour.concept3d.com/home/${encodeURIComponent(
    tourId
  )}/edit/general?aid=${encodeURIComponent(aid)}`;
};

export const getTourBuilderShareUrl = (tourId) => {
  return `https://${stage}tour.concept3d.com/share/${encodeURIComponent(
    tourId
  )}/stop/1`;
};

export const getCMSUrl = (mapId) => {
  return `https://${stage}cms.concept3d.com/map/cms/?id=${mapId}`;
};

export const get360MapUrl = () => {
  //todo how do we get info for 360 maps?
  return `https://${stage}360maps.concept3d.com/?id=1894`;
};

export const handleUrlOpen = (_url) => {
  _url && window.open(_url, '_blank');
};

export const tourBuilderProductPage =
  'https://concept3d.com/use-cases/product-page-360o-tours/';
export const interactiveMapProductPage =
  'https://concept3d.com/product-page-interactive-map/';
