import React from 'react';
import { PuffLoader } from 'react-spinners';

import './AuthLoader.scss';

const AuthLoader = () => {
  return (
    <section className="auth-loader">
      <div className="auth-loader__spinner-wrapper">
        <i className="material-icons">lock</i>
        <PuffLoader color="#009b65" />
      </div>
    </section>
  );
};

export default AuthLoader;
