import MainPage from 'pages/MainPage/MainPage';
import React from 'react';
import { Route } from 'react-router-dom';

const Routes = () => {
  return (
    <div className="content">
      <Route path="/" component={MainPage} />
    </div>
  );
};

export default Routes;
