import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Dropdown from '../../common/dropdown/Dropdown';
import SignOut from './SignOut';
import { Button } from '../../common/buttons';
import Icon from '../../common/layout/Icon';
import Avatar from '../../Avatar/Avatar';

const UserMenu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="profile-wrapper">
      <Button
        tabIndex={0}
        className="user-profile"
        handleClick={toggleMenu}
        aria-expanded={showMenu}
      >
        <Avatar />
        <Icon data-name="userMenu" iconName="arrow_drop_down" />
      </Button>
      {showMenu && (
        <Dropdown
          rootAdmin
          path={location.pathname}
          dropDownType={'accounts'}
          close={toggleMenu}
        >
          <SignOut />
        </Dropdown>
      )}
    </div>
  );
};

export default UserMenu;
