import React from 'react';
import PropTypes from 'prop-types';
import './layout.scss';

const Heading = ({ headingCopy, bodyCopy, classNames, forwardRef }) => (
  <div ref={forwardRef} className={`heading-wrapper ${classNames}`}>
    <h3 className="heading-wrapper__title">{headingCopy}</h3>
    {bodyCopy && <p className="heading-wrapper__copy">{bodyCopy}</p>}
  </div>
);

Heading.propTypes = {
  headingCopy: PropTypes.string,
  bodyCopy: PropTypes.string,
  classNames: PropTypes.string,
  forwardRef: PropTypes.object,
};

export default Heading;
