import React, { useState } from 'react';
import Card from './Card/Card';

import './Cards.scss';
import { Heading } from 'components/common/layout';
import { Button } from 'components/common/buttons';

const Cards = (props) => {
  const [showAll, setShowAll] = useState(false);
  const { title, cards = [] } = props;
  const renderCards = () =>
    cards
      .slice(0, showAll ? cards.length + 1 : 3)
      .map(({ title, description, buttons, image }, i) => {
        return (
          <Card
            key={`card-${i}`}
            title={title}
            description={description}
            image={image}
            buttons={buttons}
          />
        );
      });
  const showMoreBtn = cards.length > 3;
  return (
    <section className="cards">
      <div className="cards__header">
        <Heading headingCopy={title} />
        {showMoreBtn && (
          <Button className="text-btn" handleClick={() => setShowAll(!showAll)}>
            {showAll ? `Show Less` : `Show More`}
          </Button>
        )}
      </div>
      <div className="cards__wrapper" data-testid="card-wrapper">
        {renderCards()}
      </div>
    </section>
  );
};

export default Cards;
