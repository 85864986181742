import { useGetParsedToken } from '../../components/common/hooks';
import Help from './Help/Help';
import Map from './Map/Map';
import TourBuilder from './TourBuilder/TourBuilder';
import './MainPage.scss';

const MainPage = () => {
  const { groups, maps, tb_roles, ready } = useGetParsedToken();
  const hasMaps = maps?.length > 0;

  return (
    <section className="main-page">
      {ready && (
        <>
          <Map maps={maps} showProductPage={!hasMaps} />
          <TourBuilder
            showProductPage={!tb_roles}
            tourAccounts={groups?.tours}
          />
        </>
      )}
      <Help />
    </section>
  );
};

export default MainPage;
