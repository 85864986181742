import { useEffect, useState } from 'react';
import Cards from 'components/Cards/Cards';
import {
  handleUrlOpen,
  getTourBuilderShareUrl,
  getTourBuilderTourEditUrl,
  tourBuilderProductPage,
} from 'utils/concept3dLinks';
import { buildCardButton, buildCard } from 'utils/cardUtils';
import { getStop, getToursByAid } from 'api/tourApi';
import { getCloudinaryPortalUrl, getTourBuilderAssetUrl } from 'utils/assets';

const TourBuilder = (props) => {
  const [tours, setTours] = useState([]);
  const [stopImages, setStopImages] = useState({});
  const { tourAccounts = [], showProductPage } = props;

  useEffect(() => {
    const getTours = async () => {
      const promises = [];
      tourAccounts.forEach((id) => {
        promises.push(getToursByAid(id));
      });
      const tourData = await Promise.all(promises);
      const filterTours = tourData
        .filter((tourArray) => tourArray.length > 0)
        .flat();
      setTours(filterTours);
      getFirstStops(filterTours);
    };
    const getFirstStops = async (tours) => {
      const promises = [];
      tours.forEach(({ aid, stopIds }) =>
        promises.push(getStop(aid, stopIds[0]))
      );
      const stops = await Promise.all(promises);
      const stopImageData = stops.reduce(
        (acc = {}, { panoId, mainMediaType, id }) => {
          acc[id] = { panoId, mainMediaType };
          return acc;
        },
        {}
      );
      setStopImages(stopImageData);
    };
    getTours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCards = () => {
    const cards = tours.map((tour) => {
      const { name, aid, id, published, stopIds } = tour;
      const firstStop = stopIds[0] || {};
      const { mainMediaType, panoId } = stopImages[firstStop] || {};
      const title = name;
      const buttons = [
        buildCardButton({
          name: 'Edit Tour',
          handleClick: () => handleUrlOpen(getTourBuilderTourEditUrl(aid, id)),
        }),
        published &&
          buildCardButton({
            name: 'Public Tour',
            handleClick: () => handleUrlOpen(getTourBuilderShareUrl(id)),
          }),
      ];
      return buildCard({
        image: panoId
          ? getTourBuilderAssetUrl(mainMediaType, panoId)
          : getCloudinaryPortalUrl('tour.jpg'),
        title,
        buttons,
      });
    });

    const signUpCard = () => {
      return buildCard({
        image: getCloudinaryPortalUrl('tour.jpg'),
        description: 'Check out our 360º Tour platform!',
        title: '360º Tour',
        buttons: [
          buildCardButton({
            name: 'Product Page',
            handleClick: () => handleUrlOpen(tourBuilderProductPage),
          }),
          buildCardButton({
            name: 'Sample Tour',
            handleClick: () =>
              handleUrlOpen(getTourBuilderShareUrl('Ij6lyhm2D')),
          }),
        ],
      });
    };

    return showProductPage ? [signUpCard()] : cards;
  };
  return <Cards title="360º Tour & Tour Builder" cards={createCards()} />;
};

export default TourBuilder;
