import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const useGetParsedToken = () => {
  const { keycloak } = useKeycloak();
  const [parsedToken, setParsedToken] = useState({});
  useEffect(() => {
    const getUser = () => {
      const { maps, groups } = keycloak.idTokenParsed;
      const groupsParsed = groups.reduce((acc, group) => {
        const cmsGroup = '/CMS Accounts/';
        const tourGroup = '/tours/';

        if (group.includes('/CMS Accounts/')) {
          const { cms = [] } = acc;
          acc.cms = [...cms, group.replace(cmsGroup, '')];
        }
        if (group.includes('/tours/')) {
          const { tours = [] } = acc;
          acc.tours = [...tours, group.replace(tourGroup, '')];
        }
        return acc;
      }, {});
      setParsedToken({
        ...keycloak.idTokenParsed,
        maps: maps?.split(','),
        groups: groupsParsed,
        ready: true,
      });
    };
    if (!!keycloak.authenticated) {
      getUser();
    }
  }, [keycloak, keycloak.authenticated]);

  return parsedToken;
};

export default useGetParsedToken;
