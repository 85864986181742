import React, { useState } from 'react';
import cx from 'classnames';
import './Card.scss';

import { Heading } from '../../common/layout';
import { Button } from '../../common/buttons';
import { isMobile } from 'react-device-detect';

const Card = (props) => {
  const [focused, setFocused] = useState(false);
  const { buttons = [], title, description, image } = props;

  const hasButtons = buttons.length > 0;

  const renderButtons = () => {
    return buttons.map(({ handleClick, name = '', icon = '' }) => (
      <Button
        key={`card-button-${name}`}
        onClick={handleClick}
        onKeyPress={handleClick}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {icon && <i className="material-icons">{icon}</i>}
        &nbsp;{name}
      </Button>
    ));
  };

  const cardClasses = cx('card', {
    focused: focused || isMobile,
  });
  return (
    <div className={cardClasses}>
      <img className="card__image" src={image} alt="" />
      <Heading headingCopy={title} bodyCopy={description} />
      {hasButtons && <div className="tour-btn-wrapper">{renderButtons()}</div>}
    </div>
  );
};

export default Card;
