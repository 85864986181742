import React from 'react';
import Button from '../components/common/buttons/Button';
import DialogModal from '../components/common/modal/DialogModal/DialogModal';
import useCacheBuster from 'hooks/useCacheBuster';
import './CacheBuster.scss';

const CacheBuster = () => {
  const { isUpToDate, refreshCacheAndReload } = useCacheBuster();

  return (
    <DialogModal
      isOpen={!isUpToDate}
      className="cache-buster-modal"
      hideBackground
    >
      <div className="cache-buster-modal__content-wrapper">
        <p>
          Your app is out of date. Please refresh and reload to get the latest
          experience
        </p>
        <Button className="red-btn" handleClick={refreshCacheAndReload}>
          Refresh and Reload
        </Button>
      </div>
    </DialogModal>
  );
};

export default CacheBuster;
